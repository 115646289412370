.password-requirement {
  line-height: 12px;
  color: black;
  font-size: 12px;
  list-style: none;
}
.password-requirement li::before {
  content: "\2022";
  color: red;
  font-weight: bold;
  display: inline-block;
  width: 2em;
  margin-left: -1em;
}
.password-requirement .password-requirement--satisfied:before {
  color: green;
}
.password-requirement .password-requirement--satisfied {
  color: gray;
}