.dashboard__visit-history {
  max-height: 400px;
  overflow-y: scroll;
}

.dashboard__session-wrapper {
  flex-direction: column;
}

.dashboard__day-wrapper,
.dashboard__wesite-wrapper {
  flex-direction: row;
  width: 100%;
  justify-content: flex-start;
}

.dashboard__day-wrapper {
  max-height: 40px;
}

.dashboard__wesite-wrapper {
  max-height: 30px;
}

.week-day {
  font-size: 22px;
}

.dashboard__week-day,
.dashboard__website {
  width: 50%;
}

.dashboard__action {
  margin: 0;
  align-self: flex-end;
}

.dashboard__blocklist-header {
  display: inline-block;
}

.dashboard__blocklist-notification {
  margin-left: 10px;
  color: green;
}

.common-webiste-button {
  background: transparent;
}

.container-twellai-extension {
  display: flex;

  flex-direction: column;

  gap: 9px;

  width: 100%;

  margin-bottom: 29px;
}

.container-get-extension {
  display: flex;
  justify-content: space-between;
  align-items: center;

  flex-wrap: wrap;

  gap: 20px;

  width: 100%;

  height: auto;

  border-radius: 4px;
  background: #000;

  padding: 20px;

  > div:nth-child(1) {
    > h2 {
      color: #fff;
      font-size: 18px;
      font-style: normal;
      font-weight: 200;
      line-height: 30px;
      letter-spacing: -0.35px;

      margin: 0;
    }

    > h1 {
      color: #fff;
      font-size: 28px;
      font-style: normal;
      font-weight: 700;
      line-height: 30px;
      letter-spacing: -0.35px;

      margin: 0;
    }
  }

  > div:nth-child(2) {
    display: flex;
    justify-content: center;
    align-items: center;

    gap: 20px;

    flex-wrap: wrap;

    .extension-apple {
      min-width: 170px;
      background-image: url("../assets/images/chrome-store-badge1.svg");
      background-position: 50% 50%;

      height: 50px;

      border-radius: 4px;
    }
  }
}

.container-twellaai {
  /* background-image: url("../assets/images/backgroundtwellaai.png");
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover; */

  width: 100%;
  height: 104px;

  cursor: pointer;

  border: 2px solid #000;
  border-radius: 4px;

  box-shadow: 0px 2px 17px 0px rgba(11, 15, 34, 0.1);

  background: linear-gradient(84deg, #6900d1 19.98%, #ad52e4 87.82%);

  display: flex;
  align-items: center;
  justify-content: space-between;

  padding: 0;

  > div:nth-child(1) {
    border-radius: 4px;

    background-color: transparent;

    height: 100%;

    display: flex;
    align-items: center;

    padding-left: 20px;
    padding-right: 0;

    width: 100%;

    > span {
      color: #fff;
      font-size: 28px;
      font-style: normal;
      font-weight: 700;
      line-height: 30px;
      letter-spacing: -0.35px;

      text-align: start;
    }
  }

  > div:nth-child(2) {
    display: flex;
    align-items: center;
    justify-content: space-between;

    width: 100%;

    > img:nth-child(1) {
      margin-right: -100px;
    }

    > img {
      max-width: 100%;
      height: auto;
    }
  }
}

@media screen and (max-width: 425px) {
  .container-twellaai {
    justify-content: space-evenly;
  }

  .container-twellaai > div:nth-child(1) {
    width: 100%;
  }

  .container-twellaai > div:nth-child(2) {
    width: 50%;
    > img:nth-child(2) {
      display: none;
    }
  }

  .container-get-extension > div:nth-child(2) {
    flex-wrap: wrap !important;
  }
}
@media screen and (max-width: 320px) {
  .container-twellaai > div:nth-child(1) > span {
    font-size: 22px !important;
  }

  .container-twellaai button {
    padding: 10px;
  }

  .container-twellaai {
    background-position: 55% 50%;
  }
}

@media screen and (max-width: 780px) {
  .container-twellaai > div:nth-child(2) > img:nth-child(1) {
    margin-right: -200px;
  }
}

@media screen and (max-width: 375px) {
  .container-twellaai > div:nth-child(1) > span {
    font-size: 25px;
  }
}

.error-invalid-url {
  background-color: #ffcccc;
  color: #cc0000;
  padding: 5px 10px;
  border: 1px solid #cc0000;
  border-radius: 4px;
  font-size: 14px;
  font-weight: bold;
}

.container-subscription {
  display: flex;
  flex-direction: column;

  gap: 7px;

  > div {
    display: flex;
    justify-content: space-between;
    align-items: center;

    > h1 {
      font-size: 18px;
      line-height: 28px;
      margin: 0;
    }

    > span {
      font-size: 16px;
      // opacity: 50%;
      color: rgba(0, 0, 0, 50%);
    }

    > b {
      font-size: 18px;
      line-height: 28px;

      > span {
        color: rgba(0, 0, 0, 50%);
      }
    }
  }

  > button {
    color: rgba(221, 27, 27, 1);
    height: 48px;
    background: transparent;

    font-size: 16px;
    font-weight: 600;
    line-height: 24px;

    margin-top: 15px;
  }
}

.container-twellaai-update {
  /* background-image: url("../assets/images/backgroundtwellaai.png");
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover; */

  width: 100%;
  height: 104px;

  cursor: pointer;

  border: 2px solid #000;
  border-radius: 4px;

  box-shadow: 0px 2px 17px 0px rgba(11, 15, 34, 0.1);

  background: linear-gradient(84deg, #6900d1 19.98%, #ad52e4 87.82%);

  display: flex;
  align-items: center;
  justify-content: space-between;

  padding: 0;

  // cursor: not-allowed;

  > div:nth-child(1) {
    border-radius: 4px;

    background-color: transparent;

    height: 100%;

    display: flex;
    align-items: center;

    padding-left: 20px;
    padding-right: 0;

    width: 100%;

    > span {
      color: #fff;
      font-size: 28px;
      font-style: normal;
      font-weight: 700;
      line-height: 30px;
      letter-spacing: -0.35px;

      text-align: start;
    }
  }

  > div:nth-child(2) {
    display: flex;
    align-items: center;
    justify-content: end;

    height: 100%;
    width: 100%;
    background-image: url(('../assets/icons/webs-purple.svg'));
    background-repeat: no-repeat;
    background-size: cover;

    > img:nth-child(1) {
      margin-right: -100px;
    }

    > img {
      max-width: 100%;
      height: auto;
    }
  }
}

.button-update {
  color: rgba(255, 255, 255, 1);
  background: rgba(0, 0, 0, 1);
  height: 48px;
  width: 137px;
  margin-right: 20px;
  border-radius: 5px;
}
