.error-message {
  display: block;
  position: absolute;
  bottom: -30px;
  height: 20px;
  width: 100%;
  text-align: center;
  color: red;
  background-color: transparent;
}

.container-error-message-add-website {
  display: block;
  text-align: left;

  color: red;
  background-color: transparent;

  margin-top: 6px;
  border-radius: 4px;
  max-width: max-content;
  border: 1px solid red;

  padding: 0 5px;
}

.success-message {
  display: block;
  position: absolute;
  bottom: -30px;
  height: 20px;
  width: 100%;
  text-align: center;
  color: green;
  background-color: transparent;
}
.warning-box {
  display: block;
  width: 100%;
  text-align: center;
  color: black;
  background-color: rgb(255, 226, 172);
  padding: 15px;
  border-radius: 5px;
}
